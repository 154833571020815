import { CreateDeliveryDTO } from '@interfaces/dto/delivery.dto'
import { api } from '.'

export const delivery_api = {
  calculateDelivery: async (data: {
    weight: number
    shipment_country: string
    delivery_country: string
    shipment_type: string
    delivery_type: string
  }) => {
    return await api.get(`/delivery/calculate`, {
      params: {
        ...data,
      },
    })
  },
  createDeliveryRequest: (data: CreateDeliveryDTO) => {
    return api.post(`/delivery/create`, data)
  },
  getDeliveries: async () => {
    return await api.get(`/user/myOrders`)
  },
}
