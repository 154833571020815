import Head from 'next/head'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import PageWrapper from '@components/wrappers/PageWrapper'
import MainScreen from '@components/componentsHome/MainScreen/MainScreen'
import WhyWe from '@components/componentsHome/WhyWe/WhyWe'
import CurrentPromotions from '@components/componentsHome/CurrentPromotions/CurrentPromotions'
import OurServices from '@components/componentsHome/OurServices/OurServices'
import Feedbacks from '@components/UI/components/Feedbacks/Feedbacks'
import TextDescription from '@components/componentsHome/TextDescription/TextDescription'

import { feedbacksMock } from '@mock/feedbacksMock'
import { WhyWeMock } from '@mock/homeMock'
import { NavigationButtons } from '@components/componentsHome/NavigationButtons/NavigationButtons'
import useScrollToAnchor from '@hooks/useScrollToAnchor'

export default function Home() {
  useScrollToAnchor()
  return (
    <>
      <Head>
        <title>Логістична компанія Perevozka – Міжнародні перевезення</title>
        <meta
          name='description'
          content='Міжнародні перевезення пасажирів та вантажів / Кур’єрські і транспортні послуги / Унікальна система автобусних перевезень / Зручно, швидко, комфортно'
        />
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <main>
        <PageWrapper navLinksMock='main' accentColor='blue'>
          <MainScreen />
          <WhyWe
            WhyWeMock={WhyWeMock}
            color='yellow'
            dividerColor='blueYellow'
            svgUrl='/icons/map-mark-blue-yellow2.svg'
            description='Тільки у нас унікальна система автобусних перевезень: з адреси на адресу. Насолоджуйтесь комфортним транспортом за доступною ціною'
          />
          <CurrentPromotions />
          <OurServices />
          <Feedbacks color='blueYellow' data={feedbacksMock} />
          <TextDescription />
          <NavigationButtons />
        </PageWrapper>
      </main>
    </>
  )
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}
