export const navigationMainMock = [
  {
    id: 1,
    name: 'Переваги',
    href: '#advantages',
  },
  {
    id: 2,
    name: 'Промоакції',
    href: '#promotions',
  },
  {
    id: 3,
    name: 'Послуги',
    href: '#services',
  },
  {
    id: 4,
    name: 'Відгуки',
    href: '#feedbacks',
  },
]

export const navigationDeliveryMock = [
  {
    id: 1,
    name: 'Етапи',
    href: '#deliveryStages',
  },
  {
    id: 2,
    name: 'Заборонені',
    href: '#prohibitedGoods',
  },
  {
    id: 3,
    name: 'Калькулятор',
    href: '#calculator',
  },
  {
    id: 4,
    name: 'Оформлення',
    href: '#useServiceDelivery',
  },
]

export const navigationTransportationMock = [
  {
    id: 1,
    name: 'Забронювати',
    href: '#orderTrip',
  },
  {
    id: 2,
    name: 'Процес перевезення',
    href: '#transportationProcess',
  },
  {
    id: 3,
    name: 'Напрямки',
    href: '#transportationProcess',
  },
]

export const navigationServicesMock = [
  {
    id: 1,
    name: 'Головна',
    href: '/',
  },
  {
    id: 2,
    name: 'Переваги',
    href: '/#advantages',
  },
  {
    id: 3,
    name: 'Промоакції',
    href: '/#promotions',
  },
  {
    id: 4,
    name: 'Відгуки',
    href: '/#feedbacks',
  },
]
