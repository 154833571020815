import Button from '@components/UI/Button/Button'
import styles from './NavigationButtons.module.sass'
import { useEffect, useState } from 'react'

const items = [
  '#home',
  '#advantages',
  '#promotions',
  '#services',
  '#feedbacks',
  '#description',
]

export function NavigationButtons() {
  const [activeIndex, setActiveIndex] = useState(0)

  console.log('activeIndex', activeIndex)

  useEffect(() => {
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      const visibleEntry = entries.find((entry) => entry.isIntersecting)
      if (visibleEntry) {
        const index = items.findIndex(
          (id) => id === `#${visibleEntry.target.id}`,
        )
        setActiveIndex(index)
      }
    }

    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      threshold: 0.7,
    })

    items.forEach((id) => {
      const element = document.querySelector(id)
      if (element) observer.observe(element)
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  const handleScroll = (index: number) => {
    const target = document.querySelector(items[index])
    if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => handleScroll(activeIndex - 1)}
        disabled={activeIndex === 0}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='32'
          height='32'
          viewBox='0 0 24 24'
        >
          <path
            fill='currentColor'
            d='m12 6l.707-.707L12 4.586l-.707.707zm-1 12a1 1 0 1 0 2 0zm5.707-8.707l-4-4l-1.414 1.414l4 4zm-5.414-4l-4 4l1.414 1.414l4-4zM11 6v12h2V6z'
          />
        </svg>
      </Button>
      <Button
        onClick={() => handleScroll(activeIndex + 1)}
        disabled={activeIndex === items.length - 1}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='32'
          height='32'
          viewBox='0 0 24 24'
        >
          <path
            fill='currentColor'
            d='m12 18l-.707.707l.707.707l.707-.707zm1-12a1 1 0 1 0-2 0zm-5.707 8.707l4 4l1.414-1.414l-4-4zm5.414 4l4-4l-1.414-1.414l-4 4zM13 18V6h-2v12z'
          />
        </svg>
      </Button>
    </div>
  )
}
