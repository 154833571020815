import HeaderWithDescription from '@components/UI/HeaderWithDescription/HeaderWithDescription'
import FeedbackSlider from '@components/UI/components/FeedbackSlider/FeedbackSlider'
import NoiseBg from '../NoiseBg/NoiseBg'

import styles from './Feedbacks.module.sass'
import Header from '@components/UI/Header/Header'

interface IProps {
  data: {
    id: number
    name: string
    stars: number
    toCountry: string
    fromCountry: string
    comment: string
    imgUrl: string
  }[]
  header?: string | JSX.Element
  description?: string
  svgUrl?: string
  color?: 'yellow' | 'blue' | 'blueYellow'
  dividerColor?: 'yellow' | 'blue' | 'blueYellow'
  isPaddingTop?: boolean
  isNoiseBg?: boolean
}

const Feedbacks = ({
  data,
  isPaddingTop = true,
  isNoiseBg = false,
  color,
}: IProps) => {
  return (
    <div
      className={isPaddingTop ? styles.containerPadding : styles.container}
      id='feedbacks'
    >
      {isNoiseBg && <NoiseBg />}
      <div className={styles.headerContainer}>
        <Header color={color}>
          відгуки <span>клієнтів</span>
        </Header>
      </div>
      <div className={styles.sliderContainer}>
        <FeedbackSlider data={data} />
      </div>
    </div>
  )
}

export default Feedbacks
