import Image from 'next/image'

import SmallHeader from '@components/UI/SmallHeader/SmallHeader'

import styles from '@components/UI/components/TextTwoColumns/TextTwoColumns.module.sass'
import ReadMore from '@components/UI/ReadMore/ReadMore'

export const WhyWeMock = [
  {
    id: 1,
    title: 'Ціна, як на долоні',
    alt: 'Rerevozka',
    description:
      'Жодних “сюрпризів” у чеку!\n\nПлатите тільки за фактичну вагу, а всі тарифи — чесні й прозорі.',
    imgUrl: '/images/whyWe/money.png',
  },
  {
    id: 2,
    title: 'Швидкість — наше друге ім’я',
    alt: 'Rerevozka',
    description:
      'Ваші посилки мчать до місця призначення зі швидкістю світла.\n\nТермінові відправлення? Легко, доставимо навіть за день!',
    imgUrl: '/images/whyWe/lighting.png',
  },
  {
    id: 3,
    title: 'Замовлення за кілька кліків',
    alt: 'Rerevozka',
    description:
      'Наше оформлення — це швидко, легко і без головного болю.\n\nПара хвилин — і все готово!',
    imgUrl: '/images/whyWe/sofa.png',
  },
]

export const descriptionMock = [
  {
    id: 0,
    textContent: (
      <ReadMore maxLength={200}>
        <div className={styles.headerContainer}>
          <SmallHeader>Міжнародна логістична компанія Perevozka</SmallHeader>
        </div>

        <div className={styles.textContainer}>
          <p>
            Перевезення вантажів між містами України має бути комфортним та
            зручним, як і доставка до інших країн світу. Зробити цю мрію
            реальністю допоможуть послуги логістичної компанії Perevozka.
            Команда складається з досвідчених фахівців, які здатні підготувати
            безпечний та швидкий спосіб перевезення між містами або за кордоном.
          </p>
        </div>

        <div className={styles.imgContainer}>
          <div className={styles.imgBgCover}></div>
          <Image
            src={'/images/seo/img1.jpg'}
            layout='fill'
            alt='Логістична компанія Perevozka'
          />
        </div>
      </ReadMore>
    ),
  },
  {
    id: 1,
    textContent: (
      <ReadMore maxLength={200}>
        <div className={styles.headerContainer}>
          <SmallHeader>Послуги, які доступні вже сьогодні</SmallHeader>
        </div>

        <div className={styles.textContainer}>
          <p>
            Міжнародна організація працює з клієнтами, які потребують
            перевезення товарів або посилок на велику відстань. Сьогодні
            кваліфікованість логістів перевірили понад 100 замовників. Щоб
            отримати якісне обслуговування, достатньо звернутися за
            консультацією до менеджера. Після звернення та виконання замовлення
            за клієнтом закріплюють особистого консультанта.
          </p>
          <p>
            Замовити обслуговування міжнародної логістичної компанії Perevozka
            просто, а перелік пропозицій включає:
          </p>
          <ul>
            <li>доставку посилок з-за кордону у будь-яке місто України;</li>
            <li>перевезення пасажирів за схемою від адреси до адреси;</li>
            <li>доставляння покупок з-за кордону (Європа, Америка);</li>
            <li>оренду транспортних засобів для перевезення пасажирів.</li>
          </ul>
          <p>
            Розрахунок вартості перевезення проводять з урахуванням усіх вимог:
            необхідна швидкість надання, вибрані міста, кількість місць або
            об'єм вантажу.
          </p>
        </div>

        <div className={styles.imgContainer}>
          <div className={styles.imgBgCover}></div>
          <Image
            src={'/images/seo/img2.jpg'}
            layout='fill'
            alt='Пасажирські перевезення'
          />
        </div>
      </ReadMore>
    ),
  },
  {
    id: 2,
    textContent: (
      <ReadMore maxLength={200}>
        <div className={styles.headerContainer}>
          <SmallHeader>Як замовити бажане обслуговування</SmallHeader>
        </div>

        <div className={styles.textContainer}>
          <p>
            Використовувати пропозиції міжнародної фірми дуже просто. Для
            початку співпраці оберіть бажаний спосіб зв'язку: менеджери
            обробляють заявки в соціальних мережах, телефоном, а також на сайті.
            Оформити запит на перевезення вдасться за декілька хвилин.
          </p>
          <p>
            Визначення потрібної послуги не вимагає багато часу. Спеціаліст
            допоможе заповнити першу заявку та отримати приємну знижку. Клієнту
            достатньо вказати адреси, де починається та закінчується маршрут,
            щоб співробітники склали зручний графік пересування.
          </p>
          <p>
            З метою покращення якості роботи керівництво використовує сучасні
            методи, включно зі зниженням вартості за певних умов. Клієнтська
            база складається з тисяч замовників, які залюбки користуються
            міжнародним сервісом України. Диспетчери працюють цілодобово, тому
            надають консультації щодо етапів виконання.
          </p>
        </div>

        <div className={styles.imgContainer}>
          <div className={styles.imgBgCover}></div>
          <Image
            src={'/images/seo/img3.jpg'}
            layout='fill'
            alt='Доставка посилок з Perevozka'
          />
        </div>
      </ReadMore>
    ),
  },
  {
    id: 3,
    textContent: (
      <ReadMore maxLength={200}>
        <div className={styles.headerContainer}>
          <SmallHeader>Чому Perevozka – кращий вибір</SmallHeader>
        </div>

        <div className={styles.textContainer}>
          <p>
            Перевезення пасажирів та вантажів може бути простим та комфортним.
            Саме над цими задачами працюють спеціалісти компанії. Завдяки
            регулярному покращенню обслуговування фахівці пропонують
            скористатися сервісом, який:
          </p>
          <ol>
            <li>
              Недорогий. Перевезення пасажирів або вантажів – послуга, яка має
              попит весь рік. Пропонувати недорогі та комфортні поїздки вдається
              завдяки великій базі клієнтів та перегляду вартості. Чим більше
              замовників вибирають заброньовану вами дату, тим менша ціна за
              перевезення.
            </li>
            <li>
              Швидкий. Зекономити час вдається шляхом відмови від класичного
              способу роботи. Водії забирають пасажирів (посилки), а потім
              допомагають сісти до бажаного автобусу. Таким чином транспорт не
              очікує прибуття клієнта на вокзалі, а замовникам не доводиться
              проводити час у пробках.
            </li>
            <li>
              Комфортний. Поєднання доступної вартості та можливості зекономити
              час дозволяє виконувати перевезення у зручному режимі. Завдяки
              цьому пасажири одразу отримують бажаний сервіс та насолоджуються
              комфортною поїздкою.
            </li>
          </ol>
          <p>
            Відсутність тривалого очікування та своєчасне виконання міжнародних
            перевезень – те, що стимулює клієнтів звертатись за допомогою знову
            і знову. Використати ці та інші переваги співпраці з професіоналами
            просто.
          </p>
        </div>
      </ReadMore>
    ),
  },
  {
    id: 4,
    textContent: (
      <ReadMore maxLength={200}>
        <div className={styles.headerContainer}>
          <SmallHeader>
            Доставка: які країни доступні для замовлення перевезення
          </SmallHeader>
        </div>

        <div className={styles.textContainer}>
          <p>
            Вибір бажаної логістичної послуги із переліку можливий завдяки
            широкій географії маршрутів. Так, замовити доставлення пакунків або
            перевезення пасажирів можна до:
          </p>
          <ul>
            <li>Словаччини;</li>
            <li>Чехії;</li>
            <li>Америки;</li>
            <li>країни Європи.</li>
          </ul>
          <p>
            Диспетчери підтримують зв'язок з клієнтом впродовж усього маршруту
            перевезення, тому отримати інформацію про місце перебування пакунків
            або пасажирів дуже легко.
          </p>
        </div>
      </ReadMore>
    ),
  },
]
