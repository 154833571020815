import Link from 'next/link'
import { ReactSVG } from 'react-svg'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

// import { useTheme } from '@hooks/useTheme'
import { ISidebarMock, TAnimation } from '@interfaces/global.type'

import SideBarLinkItem from '../SideBarLinkItem/SideBarLinkItem'
import FooterLinkItem from '../FooterLinkItem/FooterLinkItem'
import LangSelect from '../components/LangSelect/LangSelect'
import Button from '../Button/Button'

import styles from './SideBar.module.sass'

import {
  sidebarMainMock,
  sidebarDeliveryMock,
  sidebarTransportationMock,
} from '@mock/sidebarMock'
import { contactsMock } from '@mock/footerMock'

interface IProps {
  animation: TAnimation
  handleToggleSidebar: () => void
  sidebarMock?: ISidebarMock | Array<any>
}

const SideBar = ({
  animation,
  handleToggleSidebar,
  sidebarMock = 'main',
}: IProps) => {
  const { t } = useTranslation('common')
  const router = useRouter()
  // const { handleTheme } = useTheme()

  const sidebarDataMock = {
    main: sidebarMainMock,
    delivery: sidebarDeliveryMock,
    transportation: sidebarTransportationMock,
  }

  const defaultStyles = {
    none: {
      container: styles.container,
      contentContainer: styles.contentContainer,
    },
    shown: {
      container: styles.containerShown,
      contentContainer: styles.contentContainerShown,
    },
    hidden: {
      container: styles.containerHidden,
      contentContainer: styles.contentContainerHidden,
    },
  }

  const sidebarData =
    typeof sidebarMock === 'string' ? sidebarDataMock[sidebarMock] : sidebarMock

  return (
    <div
      className={defaultStyles[animation].container}
      onClick={handleToggleSidebar}
    >
      <div
        className={defaultStyles[animation].contentContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.topContainer}>
          <div className={styles.logoContainer}>
            <Link href='/'>
              <ReactSVG src='/icons/logo-blue-yellow-with-text.svg' />
            </Link>
          </div>

          <div className={styles.closeIconContainer}>
            <ReactSVG src='/icons/close.svg' onClick={handleToggleSidebar} />
          </div>
        </div>

        {router.pathname !== '/login' && router.pathname !== '/cabinet' && (
          <div className={styles.btnLinkContainer}>
            <Button href='/cabinet' className='newButtonNoBlur'>
              Особистий кабінет
            </Button>
          </div>
        )}

        <div className={styles.subHeaderContainer}>
          <p>{t('sidebar.sidebarSubHeader')}</p>
        </div>

        <div className={styles.linksContainer}>
          {sidebarData.map((item) => (
            <SideBarLinkItem
              key={item.id}
              href={item.href}
              color={item.color}
              onClick={handleToggleSidebar}
            >
              {item.name}
            </SideBarLinkItem>
          ))}
        </div>
        {/*
          <div className={styles.langSelectContainer}>
          <LangSelect />
        </div>
        */}
        <div className={styles.hotLineContainer}>
          <h6>Гаряча лінія</h6>
          <p>Для зв’язку у Viber або Telegram</p>

          <div className={styles.contactsContainer}>
            {contactsMock.map((item) => (
              <FooterLinkItem
                key={item.id}
                href={item.href}
                iconSrc={item.iconSrc}
              >
                {item.title}
              </FooterLinkItem>
            ))}
          </div>
        </div>
        {/* <div style={{ marginTop: '50px' }}>
          <button onClick={handleTheme}>Switch Theme</button>
        </div> */}
      </div>
    </div>
  )
}

export default SideBar
