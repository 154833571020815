import React, { useState } from 'react'
import styles from './ReadMore.module.sass'

interface ReadMoreProps {
  children: React.ReactNode
  maxLength: number
}

const ReadMore: React.FC<ReadMoreProps> = ({ children, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className={styles.readMoreContainer}>
      <div
        style={
          isExpanded
            ? {
                height: 'auto',
                overflow: 'initial',
                transition: 'all 1s ease-in-out',
              }
            : {
                height: maxLength,
                overflow: 'hidden',
                transition: 'all 1s ease-in-out',
              }
        }
        className={`${styles.readMoreText} ${isExpanded ? 'expanded' : ''}`}
      >
        {isExpanded ? (
          children
        ) : (
          <>{React.Children.toArray(children).slice(0, maxLength)}...</>
        )}
      </div>
      <button className={styles.readMoreBtn} onClick={toggleReadMore}>
        {isExpanded ? 'Згорнути' : 'Читати більше'}
      </button>
    </div>
  )
}

export default ReadMore
