// import { useState, useEffect } from 'react'
import Slider from 'react-slick'

import { newsDto } from '@interfaces/dto/news.dto'

import NextArrow from './NextArrow'
import PrevArrow from './PrevArrow'
import NewsItem from '@components/UI/NewsItem/NewsItem'

import styles from './PromotionsSlider.module.sass'

interface IProps {
  data: newsDto[]
}

const PromotionsSlider = ({ data }: IProps) => {
  const settings = {
    customPaging: function () {
      return (
        <a href='#'>
          <div
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              //   margin: '0 5px',
            }}
          ></div>
        </a>
      )
    },
    dots: false,
    infinite: data.length > 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: true,
          dotsClass: 'slick-dots slick-thumb',
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          dotsClass: 'slick-dots slick-thumb',
        },
      },
    ],
  }

  return (
    <div className={styles.container}>
      <Slider {...settings}>
        {data.map((item) => (
          <NewsItem
            key={item?.title}
            imgUrl={item?.imgUrl}
            title={item?.title}
          />
        ))}
      </Slider>
    </div>
  )
}

export default PromotionsSlider
