import Image from 'next/image'

import SmallHeader from '../SmallHeader/SmallHeader'

import styles from './NewsItem.module.sass'

interface IProps {
  title: string
  imgUrl: string
  [key: string]: any
}

const NewsItem = ({ title = null, imgUrl = null }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <Image
          src={`data:image/png;base64,${imgUrl}`}
          alt='news item'
          layout='fill'
        />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.headerContainer}>
          <SmallHeader>{title}</SmallHeader>
        </div>
      </div>
    </div>
  )
}

export default NewsItem
