import TextTwoColumns from '@components/UI/components/TextTwoColumns/TextTwoColumns'

import styles from './TextDescription.module.sass'

import { descriptionMock } from '@mock/homeMock'

const TextDescription = () => {
  return (
    <div className={styles.container} id='description'>
      <TextTwoColumns data={descriptionMock} />
    </div>
  )
}

export default TextDescription
