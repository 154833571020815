import NoiseBg from '@components/UI/components/NoiseBg/NoiseBg'
import HeaderWithDescription from '@components/UI/HeaderWithDescription/HeaderWithDescription'
import WhyWeItem from '../WhyWeItem/WhyWeItem'

import styles from './WhyWe.module.sass'
import Header from '@components/UI/Header/Header'

// import { WhyWeMock } from '@mock/homeMock'

interface IProps {
  header?: string
  color?: 'yellow' | 'blue'
  dividerColor?: 'yellow' | 'blue' | 'blueYellow'
  isNoise?: boolean
  svgUrl?: string
  description?: string | JSX.Element
  WhyWeMock: any
}

const WhyWe = ({
  header = 'Наші Переваги',
  color = 'yellow',
  dividerColor = 'yellow',
  isNoise = false,
  svgUrl,
  description,
  WhyWeMock = [],
}: IProps) => {
  return (
    <div className={styles.container} id='advantages'>
      {isNoise && <NoiseBg />}
      <div className={styles.topContainer}>
        <div className={styles.headerContainer}>
          <Header color='yellow'>
            наші <span>переваги</span>
          </Header>
        </div>
      </div>
      <div className={styles.contentContainer}>
        {WhyWeMock.map((item) => (
          <WhyWeItem
            color={color}
            key={item.id}
            title={item.title}
            description={item.description}
            imgUrl={item.imgUrl}
            alt={item.alt}
          />
        ))}
      </div>
    </div>
  )
}

export default WhyWe
