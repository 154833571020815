import { useEffect } from 'react'

const useScrollToAnchor = () => {
  useEffect(() => {
    const hash = window.location.hash
    if (hash) {
      const anchor = document.querySelector(hash)
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [])
}

export default useScrollToAnchor
