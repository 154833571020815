import { StateCreator } from 'zustand'

import { IDeliveryRequestResponse } from '@interfaces/dto/delivery.dto'
import { delivery_api } from '@api/delivery'

export interface IDeliveryRequestSlice {
  userDeliveries: IDeliveryRequestResponse[] | []
  getUserDeliveries: () => Promise<any>
}

export const useDeliveryRequestSlice: StateCreator<IDeliveryRequestSlice> = (
  set,
) => ({
  userDeliveries: [],
  getUserDeliveries: async () => {
    const response = await delivery_api.getDeliveries()

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({ ...state, userDeliveries: data.orders.delivery }))
      return response
    }
  },
})
