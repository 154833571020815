import { ReactSVG } from 'react-svg'
import Image from 'next/image'
import Link from 'next/link'

import Button from '@components/UI/Button/Button'

import styles from './MainScreen.module.sass'

const MainScreen = () => {
  return (
    <div className={styles.container} id='home'>
      <div className={styles.bgImageCoverContainer}></div>
      <div className={styles.bgImageContainer}>
        <Image
          priority
          src='/images/mainScreenBg.png'
          layout='fill'
          alt='perevozka platform'
          placeholder='blur'
          blurDataURL='/images/mainScreenBg.png'
        />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.textContainer}>
            <h1>
              <span className={styles.blue}>Міжнародна</span>{' '}
              <span className={styles.yellow}>логістична</span> компанія
              Perevozka
            </h1>
            <p>Сервіс доставки посилок та пасажирських перевезень</p>

            <div className={styles.btnContainer}>
              <Button href='/services' className='newButton'>
                Обрати послугу
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.imgContainer}>
            <img src='/images/man.webp' alt='Логістична компанія Perevozka' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainScreen
