import { useEffect, useState } from 'react'

import { INavigationTypes } from '@interfaces/global.type'

import NavLinkItem from '../NavLinkItem/NavLinkItem'

import styles from './NavLinksContainer.module.sass'

import {
  navigationMainMock,
  navigationDeliveryMock,
  navigationTransportationMock,
  navigationServicesMock,
} from '@mock/navigationMock'

interface IProps {
  navLinksMock: INavigationTypes
  accentColor?: 'yellow' | 'blue'
}

const getNavigationMockForAnchorsStyles = (navLinksMock: INavigationTypes) => {
  switch (navLinksMock) {
    case 'main':
      return navigationMainMock
    case 'delivery':
      return navigationDeliveryMock
    case 'transportation':
      return navigationTransportationMock
    case 'services':
      return navigationServicesMock
    default:
      null
  }
}

const NavLinksContainer = ({ navLinksMock, accentColor = 'blue' }: IProps) => {
  const [activeSection, setActiveSection] = useState(null)

  useEffect(() => {
    const mock = getNavigationMockForAnchorsStyles(navLinksMock) //get needed mock for current page

    const mainSectionIds = mock.map((item) => item.href) //get ids of anchors

    const handleScroll = () => {
      if (mock === null) return

      const currentPosition = window.pageYOffset

      const topElementsPositions = mainSectionIds.map((id) => {
        const element = document.querySelector(id)
        return element.getBoundingClientRect().top + window.pageYOffset //get top position of each anchor
      })

      const bottomLastElementPosition =
        document
          .querySelector(mainSectionIds[mainSectionIds.length - 1])
          .getBoundingClientRect().bottom + window.pageYOffset //get bottom position of last anchor

      for (let i = 0; i < topElementsPositions.length; i++) {
        if (
          currentPosition >= topElementsPositions[i] &&
          currentPosition <= bottomLastElementPosition
        ) {
          setActiveSection(mainSectionIds[i]) //if current position is between top of anchor and bottom positions of the last one, set active section
        } else if (currentPosition < topElementsPositions[0]) {
          setActiveSection(null) //if current position is less than top position of first anchor, set active section to null
        } else if (currentPosition >= bottomLastElementPosition) {
          setActiveSection(null) //if current position is more than bottom position of last anchor, set active section to null
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  switch (navLinksMock) {
    case 'main':
      return (
        <div className={styles.container}>
          {navigationMainMock.map((item) => (
            <NavLinkItem
              key={item.id}
              href={item.href}
              accentColor={accentColor}
              isActive={activeSection === item.href}
            >
              {item.name}
            </NavLinkItem>
          ))}
        </div>
      )
    case 'delivery':
      return (
        <div className={styles.container}>
          {navigationDeliveryMock.map((item) => (
            <NavLinkItem
              key={item.id}
              href={item.href}
              accentColor={accentColor}
              isActive={activeSection === item.href}
            >
              {item.name}
            </NavLinkItem>
          ))}
        </div>
      )
    case 'transportation':
      return (
        <div className={styles.container}>
          {navigationTransportationMock.map((item) => (
            <NavLinkItem
              key={item.id}
              href={item.href}
              accentColor={accentColor}
              isActive={activeSection === item.href}
            >
              {item.name}
            </NavLinkItem>
          ))}
        </div>
      )
    case 'services':
      return (
        <div className={styles.container}>
          {navigationTransportationMock.map((item) => (
            <NavLinkItem
              key={item.id}
              href={item.href}
              accentColor={accentColor}
              isActive={activeSection === item.href}
            >
              {item.name}
            </NavLinkItem>
          ))}
        </div>
      )
    default:
      null
  }
}

export default NavLinksContainer
